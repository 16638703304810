<template>
  <div>
    <KTCard headClass="border-secondary" example>
      <template v-slot:title>
        <h6>{{ title }}</h6>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <span
            class="example-copy"
            :class="copyClass"
            data-toggle="tooltip"
            v-b-tooltip.hover.top="copyText"
            @click="copyElementToClipboard"
          ></span>

          <span
            v-if="summary && summary.summaryLog.mode === 'preview'"
            class="svg-icon svg-icon-md pointer mr-2"
            :class="{ 'svg-icon-primary': summary.summaryLog.mode === '' }"
            data-toggle="tooltip"
            v-b-tooltip.hover.top="'Save'"
            @click="updateStatus()"
          >
            <inline-svg src="media/svg/icons/General/Save.svg"></inline-svg>
          </span>

          <i
            v-if="summary && summary.summaryLog.mode !== ''"
            class="flaticon2-checkmark text-muted"
            data-toggle="tooltip"
            v-b-tooltip.hover.top="'Done'"
            v-b-modal.confirm-sent-modal
            @click="copy"
          >
            <!-- <inline-svg src="media/svg/icons/Navigation/Check.svg"></inline-svg> -->
          </i>
        </div>
      </template>
      <template v-slot:body>
        <div v-if="isReady && summary">
          <div :id="id">
            <div>
              อัปเดต engagement
              {{ summary.topic.brand ? `[${summary.topic.brand}] ` : ""
              }}{{ summary.topic.name }} -
              <span v-if="summary.isFirst">วันที่ {{ summary.to_text }} น.</span
              ><span v-else
                >วันที่ {{ summary.from_text }} น. ถึง
                {{ summary.to_text }} น.</span
              >
            </div>
            <div>
              Total Post :
              {{ summary.summaryEngagement.totalPost.all | number_format }}
              <span
                v-if="!summary.isFirst"
                :class="{
                  'summary-increment-text':
                    summary.summaryEngagement.diffTotalPost > 0
                }"
                >(+{{
                  summary.summaryEngagement.diffTotalPost | number_format
                }})</span
              >
              [
              <span
                v-for="(source_engagement, i) in summary.sources_engagement"
                :key="i"
                >{{ source_engagement | number_format }}</span
              >
              ]
            </div>
            <div>
              Total Engagement :
              {{ summary.summaryEngagement.totalEngagement | number_format }}
              <span
                v-if="!summary.isFirst"
                :class="{
                  'summary-increment-text':
                    summary.summaryEngagement.diffTotalEngagement > 0
                }"
                >(+{{
                  summary.summaryEngagement.diffTotalEngagement | number_format
                }})</span
              >
            </div>

            <div v-if="summary.sourceEngagement.tiktok.to.totalEngagement">
              Total Engagement (exclude TikTok) :
              {{
                (summary.summaryEngagement.totalEngagement -
                  summary.sourceEngagement.tiktok.to.totalEngagement)
                  | number_format
              }}
              <span
                v-if="!summary.isFirst"
                :class="{
                  'summary-increment-text':
                    summary.summaryEngagement.diffTotalEngagement -
                      summary.sourceEngagement.tiktok.diff.totalEngagement >
                    0
                }"
                >(+{{
                  (summary.summaryEngagement.diffTotalEngagement -
                    summary.sourceEngagement.tiktok.diff.totalEngagement)
                    | number_format
                }})</span
              >
            </div>

            <div v-if="summary.sourceEngagement.tiktok.to.totalEngagement">
              Total TikTok Engagement :
              {{
                summary.sourceEngagement.tiktok.to.totalEngagement
                  | number_format
              }}
              <span
                v-if="!summary.isFirst"
                :class="{
                  'summary-increment-text':
                    summary.sourceEngagement.tiktok.diff.totalEngagement > 0
                }"
                >(+{{
                  summary.sourceEngagement.tiktok.diff.totalEngagement
                    | number_format
                }})</span
              >
            </div>

            <div>
              Total Comment :
              {{ sourceCommentInfo.totalComment | number_format }}
              <span
                v-if="!summary.isFirst"
                :class="{
                  'summary-increment-text':
                    sourceCommentInfo.totalDiffComment > 0
                }"
                >(+{{
                  sourceCommentInfo.totalDiffComment | number_format
                }})</span
              >
            </div>

            <div
              v-if="
                sourceCommentInfo.totalComment -
                  summary.sourceEngagement.tiktok.to.comment >
                0
              "
            >
              Total Comment (exclude TikTok) :
              {{
                (sourceCommentInfo.totalComment -
                  summary.sourceEngagement.tiktok.to.comment)
                  | number_format
              }}

              <span
                v-if="!summary.isFirst"
                :class="{
                  'summary-increment-text':
                    sourceCommentInfo.totalDiffComment -
                      summary.sourceEngagement.tiktok.diff.comment >
                    0
                }"
                >(+{{
                  (sourceCommentInfo.totalDiffComment -
                    summary.sourceEngagement.tiktok.diff.comment)
                    | number_format
                }})</span
              >
            </div>

            <div v-if="summary.summaryEngagement.totalView">
              Total View :
              {{ summary.summaryEngagement.totalView | number_format }}
              <span
                v-if="!summary.isFirst"
                :class="{
                  'summary-increment-text':
                    summary.summaryEngagement.diffTotalView > 0
                }"
                >(+{{
                  summary.summaryEngagement.diffTotalView | number_format
                }})</span
              >
              <div
                v-if="
                  summary.summaryEngagement.totalView &&
                  summary.sourceEngagement.twitter.to.totalView
                "
              >
                Total View (exclude twitter) :
                {{
                  (summary.summaryEngagement.totalView -
                    summary.sourceEngagement.twitter.to.totalView)
                    | number_format
                }}
                <span
                  v-if="!summary.isFirst"
                  :class="{
                    'summary-increment-text':
                      summary.summaryEngagement.diffTotalView -
                        summary.sourceEngagement.twitter.diff.totalView >
                      0
                  }"
                  >(+{{
                    (summary.summaryEngagement.diffTotalView -
                      summary.sourceEngagement.twitter.diff.totalView)
                      | number_format
                  }})</span
                >
              </div>
            </div>

            <div
              v-if="
                summary.summaryEngagement.totalView &&
                summary.sourceEngagement.twitter.to.totalView
              "
            >
              Total Twitter View :
              {{
                summary.sourceEngagement.twitter.to.totalView | number_format
              }}
              <span
                v-if="!summary.isFirst"
                :class="{
                  'summary-increment-text':
                    summary.sourceEngagement.twitter.diff.totalView > 0
                }"
                >(+{{
                  summary.sourceEngagement.twitter.diff.totalView
                    | number_format
                }})</span
              >
            </div>

            <div
              v-if="
                summary.summaryEngagement.totalView &&
                summary.sourceEngagement.tiktok.to.totalView
              "
            >
              Total View (exclude tiktok) :
              {{
                (summary.summaryEngagement.totalView -
                  summary.sourceEngagement.tiktok.to.totalView)
                  | number_format
              }}
              <span
                v-if="!summary.isFirst"
                :class="{
                  'summary-increment-text':
                    summary.summaryEngagement.diffTotalView -
                      summary.sourceEngagement.tiktok.diff.totalView >
                    0
                }"
                >(+{{
                  (summary.summaryEngagement.diffTotalView -
                    summary.sourceEngagement.tiktok.diff.totalView)
                    | number_format
                }})</span
              >
            </div>

            <div
              v-if="
                summary.summaryEngagement.totalView &&
                summary.sourceEngagement.tiktok.to.totalView
              "
            >
              Total Tiktok View :
              {{ summary.sourceEngagement.tiktok.to.totalView | number_format }}
              <span
                v-if="!summary.isFirst"
                :class="{
                  'summary-increment-text':
                    summary.sourceEngagement.tiktok.diff.totalView > 0
                }"
                >(+{{
                  summary.sourceEngagement.tiktok.diff.totalView
                    | number_format
                }})</span
              >
            </div>

            <div>
              Total Share :
              {{ sourceShareInfo.totalShare | number_format }}
              <span
                v-if="!summary.isFirst"
                :class="{
                  'summary-increment-text': sourceShareInfo.totalDiffShare > 0
                }"
                >(+{{ sourceShareInfo.totalDiffShare | number_format }})</span
              >
              <br />
              Total Like :
              {{ sourceLikeInfo.totalLike | number_format }}
              <span
                v-if="!summary.isFirst"
                :class="{
                  'summary-increment-text': sourceLikeInfo.totalDiffLike > 0
                }"
                >(+{{ sourceLikeInfo.totalDiffLike | number_format }})</span
              >
              <br />
              <br />
              Post + Comment :
              {{ sourcePostCommentInfo.totalPostComment | number_format }}
              <span
                v-if="!summary.isFirst"
                :class="{
                  'summary-increment-text':
                    sourcePostCommentInfo.totalDiffPostComment > 0
                }"
                >(+{{
                  sourcePostCommentInfo.totalDiffPostComment | number_format
                }})</span
              >
              <br />
              Post + Comment (exclude tiktok comment):
              {{
                (sourcePostCommentInfo.totalPostComment -
                  summary.sourceEngagement.tiktok.to.comment)
                  | number_format
              }}
              <span
                v-if="!summary.isFirst"
                :class="{
                  'summary-increment-text':
                    sourcePostCommentInfo.totalDiffPostComment -
                      summary.sourceEngagement.tiktok.diff.comment >
                    0
                }"
                >(+{{
                  (sourcePostCommentInfo.totalDiffPostComment -
                    summary.sourceEngagement.tiktok.diff.comment)
                    | number_format
                }})</span
              >
            </div>
            <div v-if="summary.matchInfluencers.length">
              <br />
              <div>
                Media/Influencer{{
                  summary.matchInfluencers.length
                    ? ` (Total ${summary.matchInfluencers.reduce(
                        (prev, curr) => (prev += curr.count),
                        0
                      )} posts) (${summary.matchInfluencers.length} medias)`
                    : ""
                }}
              </div>
              <div v-for="(influencer, i) in summary.matchInfluencers" :key="i">
                {{ influencer.isNew ? "*" : "" }}- {{ influencer.name }}
                {{ influencer.count ? ` (${influencer.count} posts)` : "" }}
              </div>
            </div>

            <div>
              <br />

              <div v-if="summary.summaryLog.isShowSentiment">
                Comment Summary:
                {{ sentimentData.totalCommentSummary.toLocaleString() }}<br />
                Neutral {{ sentimentData.percentNeutral.toFixed(2) }}% (
                {{ sentimentData.totalNeutral.toLocaleString() }} )<br />
                Negative to KBank
                {{ sentimentData.percentNegKbank.toFixed(2) }}% (
                {{ sentimentData.totalNegKbank.toLocaleString() }} )<br />
                Negative to Bank
                {{ sentimentData.percentNegBank.toFixed(2) }}% (
                {{ sentimentData.totalNegBank.toLocaleString() }} )<br />
                Negative (Others)
                {{ sentimentData.percentNegOther.toFixed(2) }}% (
                {{ sentimentData.totalNegOther.toLocaleString() }} )<br />
                Positive to KBank
                {{ sentimentData.percentPosKbank.toFixed(2) }}% (
                {{ sentimentData.totalPosKbank.toLocaleString() }} )<br />
                Positive to Bank
                {{ sentimentData.percentPosBank.toFixed(2) }}% (
                {{ sentimentData.totalPosBank.toLocaleString() }} )<br />
                Positive (Others)
                {{ sentimentData.percentPosOther.toFixed(2) }}% (
                {{ sentimentData.totalPosOther.toLocaleString() }} )<br />
                N/A comments = {{ sentimentData.NAComment.toLocaleString()
                }}<br /><br />
              </div>
              <div v-else>Comment Summary:</div>
              <div v-html="summary.comment"></div>
            </div>

            <div v-if="summary.sourceEngagement.facebook.to.totalPost">
              <br />
              <div>
                Total in Facebook:
                {{
                  summary.sourceEngagement.facebook.to.totalEngagement
                    | number_format
                }}
                <span
                  v-if="!summary.isFirst"
                  :class="{
                    'summary-increment-text':
                      summary.sourceEngagement.facebook.diff.totalEngagement > 0
                  }"
                  >(+{{
                    summary.sourceEngagement.facebook.diff.totalEngagement
                      | number_format
                  }})</span
                >
              </div>
              <div>
                Like:
                {{ summary.sourceEngagement.facebook.to.like | number_format }}
                <span
                  v-if="!summary.isFirst"
                  :class="{
                    'summary-increment-text':
                      summary.sourceEngagement.facebook.diff.like > 0
                  }"
                  >(+{{
                    summary.sourceEngagement.facebook.diff.like | number_format
                  }})</span
                >
              </div>
              <div>
                Share:
                {{
                  summary.sourceEngagement.facebook.to.retweet | number_format
                }}
                <span
                  v-if="!summary.isFirst"
                  :class="{
                    'summary-increment-text':
                      summary.sourceEngagement.facebook.diff.retweet > 0
                  }"
                  >(+{{
                    summary.sourceEngagement.facebook.diff.retweet
                      | number_format
                  }})</span
                >
              </div>
              <div>
                Comment:
                {{
                  summary.sourceEngagement.facebook.to.comment | number_format
                }}
                <span
                  v-if="!summary.isFirst"
                  :class="{
                    'summary-increment-text':
                      summary.sourceEngagement.facebook.diff.comment > 0
                  }"
                  >(+{{
                    summary.sourceEngagement.facebook.diff.comment
                      | number_format
                  }})</span
                >
              </div>
              <div>
                View:
                {{ summary.sourceEngagement.facebook.to.view | number_format }}
                <span
                  v-if="!summary.isFirst"
                  :class="{
                    'summary-increment-text':
                      summary.sourceEngagement.facebook.diff.view > 0
                  }"
                  >(+{{
                    summary.sourceEngagement.facebook.diff.view | number_format
                  }})</span
                >
              </div>
            </div>

            <div v-if="summary.sourceEngagement.twitter.to.totalPost">
              <br />
              <div>
                Total in Twitter:
                {{
                  summary.sourceEngagement.twitter.to.totalEngagement
                    | number_format
                }}
                <span
                  v-if="!summary.isFirst"
                  :class="{
                    'summary-increment-text':
                      summary.sourceEngagement.twitter.diff.totalEngagement > 0
                  }"
                  >(+{{
                    summary.sourceEngagement.twitter.diff.totalEngagement
                      | number_format
                  }})</span
                >
              </div>
              <div>
                Like:
                {{ summary.sourceEngagement.twitter.to.like | number_format }}
                <span
                  v-if="!summary.isFirst"
                  :class="{
                    'summary-increment-text':
                      summary.sourceEngagement.twitter.diff.like > 0
                  }"
                  >(+{{
                    summary.sourceEngagement.twitter.diff.like | number_format
                  }})</span
                >
              </div>
              <div>
                Share:
                {{
                  summary.sourceEngagement.twitter.to.retweet | number_format
                }}
                <span
                  v-if="!summary.isFirst"
                  :class="{
                    'summary-increment-text':
                      summary.sourceEngagement.twitter.diff.retweet > 0
                  }"
                  >(+{{
                    summary.sourceEngagement.twitter.diff.retweet
                      | number_format
                  }})</span
                >
              </div>

              <div>
                View:
                {{ summary.sourceEngagement.twitter.to.view | number_format }}
                <span
                  v-if="!summary.isFirst"
                  :class="{
                    'summary-increment-text':
                      summary.sourceEngagement.twitter.diff.view > 0
                  }"
                  >(+{{
                    summary.sourceEngagement.twitter.diff.view | number_format
                  }})</span
                >
              </div>
            </div>

            <div v-if="summary.sourceEngagement.pantip.to.totalPost">
              <br />
              <div>
                Total in Pantip:
                {{
                  summary.sourceEngagement.pantip.to.totalEngagement
                    | number_format
                }}
                <span
                  v-if="!summary.isFirst"
                  :class="{
                    'summary-increment-text':
                      summary.sourceEngagement.pantip.diff.totalEngagement > 0
                  }"
                  >(+{{
                    summary.sourceEngagement.pantip.diff.totalEngagement
                      | number_format
                  }})</span
                >
              </div>
              <div>
                Like:
                {{ summary.sourceEngagement.pantip.to.like | number_format }}
                <span
                  v-if="!summary.isFirst"
                  :class="{
                    'summary-increment-text':
                      summary.sourceEngagement.pantip.diff.like > 0
                  }"
                  >(+{{
                    summary.sourceEngagement.pantip.diff.like | number_format
                  }})</span
                >
              </div>
              <div>
                Share:
                {{ summary.sourceEngagement.pantip.to.retweet | number_format }}
                <span
                  v-if="!summary.isFirst"
                  :class="{
                    'summary-increment-text':
                      summary.sourceEngagement.pantip.diff.retweet > 0
                  }"
                  >(+{{
                    summary.sourceEngagement.pantip.diff.retweet
                      | number_format
                  }})</span
                >
              </div>
              <div>
                Comment:
                {{ summary.sourceEngagement.pantip.to.comment | number_format }}
                <span
                  v-if="!summary.isFirst"
                  :class="{
                    'summary-increment-text':
                      summary.sourceEngagement.pantip.diff.comment > 0
                  }"
                  >(+{{
                    summary.sourceEngagement.pantip.diff.comment
                      | number_format
                  }})</span
                >
              </div>
            </div>

            <div v-if="summary.sourceEngagement.website.to.totalPost">
              <br />
              <div>
                Total in Website:
                {{
                  summary.sourceEngagement.website.to.totalEngagement
                    | number_format
                }}
                <span
                  v-if="!summary.isFirst"
                  :class="{
                    'summary-increment-text':
                      summary.sourceEngagement.website.diff.totalEngagement > 0
                  }"
                  >(+{{
                    summary.sourceEngagement.website.diff.totalEngagement
                      | number_format
                  }})</span
                >
              </div>
              <div>
                Like:
                {{ summary.sourceEngagement.website.to.like | number_format }}
                <span
                  v-if="!summary.isFirst"
                  :class="{
                    'summary-increment-text':
                      summary.sourceEngagement.website.diff.like > 0
                  }"
                  >(+{{
                    summary.sourceEngagement.website.diff.like | number_format
                  }})</span
                >
              </div>
              <div>
                Share:
                {{
                  summary.sourceEngagement.website.to.retweet | number_format
                }}
                <span
                  v-if="!summary.isFirst"
                  :class="{
                    'summary-increment-text':
                      summary.sourceEngagement.website.diff.retweet > 0
                  }"
                  >(+{{
                    summary.sourceEngagement.website.diff.retweet
                      | number_format
                  }})</span
                >
              </div>
              <div>
                Comment:
                {{
                  summary.sourceEngagement.website.to.comment | number_format
                }}
                <span
                  v-if="!summary.isFirst"
                  :class="{
                    'summary-increment-text':
                      summary.sourceEngagement.website.diff.comment > 0
                  }"
                  >(+{{
                    summary.sourceEngagement.website.diff.comment
                      | number_format
                  }})</span
                >
              </div>
              <div>
                View:
                {{ summary.sourceEngagement.website.to.view | number_format }}
                <span
                  v-if="!summary.isFirst"
                  :class="{
                    'summary-increment-text':
                      summary.sourceEngagement.website.diff.view > 0
                  }"
                  >(+{{
                    summary.sourceEngagement.website.diff.view | number_format
                  }})</span
                >
              </div>
            </div>

            <div v-if="summary.sourceEngagement.instagram.to.totalPost">
              <br />
              <div>
                Total in Instagram:
                {{
                  summary.sourceEngagement.instagram.to.totalEngagement
                    | number_format
                }}
                <span
                  v-if="!summary.isFirst"
                  :class="{
                    'summary-increment-text':
                      summary.sourceEngagement.instagram.diff.totalEngagement >
                      0
                  }"
                  >(+{{
                    summary.sourceEngagement.instagram.diff.totalEngagement
                      | number_format
                  }})</span
                >
              </div>
              <div>
                Like:
                {{ summary.sourceEngagement.instagram.to.like | number_format }}
                <span
                  v-if="!summary.isFirst"
                  :class="{
                    'summary-increment-text':
                      summary.sourceEngagement.instagram.diff.like > 0
                  }"
                  >(+{{
                    summary.sourceEngagement.instagram.diff.like
                      | number_format
                  }})</span
                >
              </div>
              <div>
                Comment:
                {{
                  summary.sourceEngagement.instagram.to.comment | number_format
                }}
                <span
                  v-if="!summary.isFirst"
                  :class="{
                    'summary-increment-text':
                      summary.sourceEngagement.instagram.diff.comment > 0
                  }"
                  >(+{{
                    summary.sourceEngagement.instagram.diff.comment
                      | number_format
                  }})</span
                >
              </div>
              <div>
                View:
                {{ summary.sourceEngagement.instagram.to.view | number_format }}
                <span
                  v-if="!summary.isFirst"
                  :class="{
                    'summary-increment-text':
                      summary.sourceEngagement.instagram.diff.view > 0
                  }"
                  >(+{{
                    summary.sourceEngagement.instagram.diff.view
                      | number_format
                  }})</span
                >
              </div>
            </div>

            <div v-if="summary.sourceEngagement.youtube.to.totalPost">
              <br />
              <div>
                Total in Youtube:
                {{
                  summary.sourceEngagement.youtube.to.totalEngagement
                    | number_format
                }}
                <span
                  v-if="!summary.isFirst"
                  :class="{
                    'summary-increment-text':
                      summary.sourceEngagement.youtube.diff.totalEngagement > 0
                  }"
                  >(+{{
                    summary.sourceEngagement.youtube.diff.totalEngagement
                      | number_format
                  }})</span
                >
              </div>
              <div>
                Like:
                {{ summary.sourceEngagement.youtube.to.like | number_format }}
                <span
                  v-if="!summary.isFirst"
                  :class="{
                    'summary-increment-text':
                      summary.sourceEngagement.youtube.diff.like > 0
                  }"
                  >(+{{
                    summary.sourceEngagement.youtube.diff.like | number_format
                  }})</span
                >
              </div>
              <div>
                Comment:
                {{
                  summary.sourceEngagement.youtube.to.comment | number_format
                }}
                <span
                  v-if="!summary.isFirst"
                  :class="{
                    'summary-increment-text':
                      summary.sourceEngagement.youtube.diff.comment > 0
                  }"
                  >(+{{
                    summary.sourceEngagement.youtube.diff.comment
                      | number_format
                  }})</span
                >
              </div>
              <div>
                View:
                {{ summary.sourceEngagement.youtube.to.view | number_format }}
                <span
                  v-if="!summary.isFirst"
                  :class="{
                    'summary-increment-text':
                      summary.sourceEngagement.youtube.diff.view > 0
                  }"
                  >(+{{
                    summary.sourceEngagement.youtube.diff.view | number_format
                  }})</span
                >
              </div>
            </div>

            <div v-if="summary.sourceEngagement.tiktok.to.totalPost">
              <br />
              <div>
                Total in Tiktok:
                {{
                  summary.sourceEngagement.tiktok.to.totalEngagement
                    | number_format
                }}
                <span
                  v-if="!summary.isFirst"
                  :class="{
                    'summary-increment-text':
                      summary.sourceEngagement.tiktok.diff.totalEngagement > 0
                  }"
                  >(+{{
                    summary.sourceEngagement.tiktok.diff.totalEngagement
                      | number_format
                  }})</span
                >
              </div>
              <div>
                Like:
                {{ summary.sourceEngagement.tiktok.to.like | number_format }}
                <span
                  v-if="!summary.isFirst"
                  :class="{
                    'summary-increment-text':
                      summary.sourceEngagement.tiktok.diff.like > 0
                  }"
                  >(+{{
                    summary.sourceEngagement.tiktok.diff.like | number_format
                  }})</span
                >
              </div>
              <div>
                Share:
                {{ summary.sourceEngagement.tiktok.to.retweet | number_format }}
                <span
                  v-if="!summary.isFirst"
                  :class="{
                    'summary-increment-text':
                      summary.sourceEngagement.tiktok.diff.retweet > 0
                  }"
                  >(+{{
                    summary.sourceEngagement.tiktok.diff.retweet
                      | number_format
                  }})</span
                >
              </div>
              <div>
                Comment:
                {{ summary.sourceEngagement.tiktok.to.comment | number_format }}
                <span
                  v-if="!summary.isFirst"
                  :class="{
                    'summary-increment-text':
                      summary.sourceEngagement.tiktok.diff.comment > 0
                  }"
                  >(+{{
                    summary.sourceEngagement.tiktok.diff.comment
                      | number_format
                  }})</span
                >
              </div>
              <div>
                View:
                {{ summary.sourceEngagement.tiktok.to.view | number_format }}
                <span
                  v-if="!summary.isFirst"
                  :class="{
                    'summary-increment-text':
                      summary.sourceEngagement.tiktok.diff.view > 0
                  }"
                  >(+{{
                    summary.sourceEngagement.tiktok.diff.view | number_format
                  }})</span
                >
              </div>
            </div>

            <div v-if="summary.topMostActive.length">
              <br />
              <div>Top Most Active:</div>
              <div v-for="(post, i) in summary.topMostActive" :key="i">
                <div>{{ i + 1 }}. {{ post.link }}</div>
                <div class="ml-4">
                  <span
                    >Like: {{ post.engagement.like | number_format }}
                    <span
                      v-if="!summary.isFirst"
                      :class="{
                        'summary-increment-text': post.diffEngagement.like > 0
                      }"
                      >(+{{ post.diffEngagement.like | number_format }})</span
                    ></span
                  >&nbsp;<span
                    >{{ post.source === "twitter" ? "Retweet" : "Share" }}:
                    {{ post.engagement.retweet | number_format }}
                    <span
                      v-if="!summary.isFirst"
                      :class="{
                        'summary-increment-text':
                          post.diffEngagement.retweet > 0
                      }"
                      >(+{{
                        post.diffEngagement.retweet | number_format
                      }})</span
                    ></span
                  >&nbsp;<span
                    >Comment: {{ post.engagement.comment | number_format }}
                    <span
                      v-if="!summary.isFirst"
                      :class="{
                        'summary-increment-text':
                          post.diffEngagement.comment > 0
                      }"
                      >(+{{
                        post.diffEngagement.comment | number_format
                      }})</span
                    ></span
                  >&nbsp;<span
                    >View: {{ post.engagement.view | number_format }}
                    <span
                      v-if="!summary.isFirst"
                      :class="{
                        'summary-increment-text': post.diffEngagement.view > 0
                      }"
                      >(+{{ post.diffEngagement.view | number_format }})</span
                    ></span
                  >
                </div>
              </div>
            </div>

            <div v-if="summary.topPost.length">
              <br />
              <div>Top Post:</div>
              <div v-for="(post, i) in summary.topPost" :key="i">
                <div>{{ i + 1 }}. {{ post.link }}</div>
                <div class="ml-4">
                  <span
                    >Like: {{ post.engagement.like | number_format }}
                    <span
                      v-if="!summary.isFirst"
                      :class="{
                        'summary-increment-text': post.diffEngagement.like > 0
                      }"
                      >(+{{ post.diffEngagement.like | number_format }})</span
                    ></span
                  >&nbsp;<span
                    >{{ post.source === "twitter" ? "Retweet" : "Share" }}:
                    {{ post.engagement.retweet | number_format }}
                    <span
                      v-if="!summary.isFirst"
                      :class="{
                        'summary-increment-text':
                          post.diffEngagement.retweet > 0
                      }"
                      >(+{{
                        post.diffEngagement.retweet | number_format
                      }})</span
                    ></span
                  >&nbsp;<span
                    >Comment: {{ post.engagement.comment | number_format }}
                    <span
                      v-if="!summary.isFirst"
                      :class="{
                        'summary-increment-text':
                          post.diffEngagement.comment > 0
                      }"
                      >(+{{
                        post.diffEngagement.comment | number_format
                      }})</span
                    ></span
                  >&nbsp;<span
                    >View: {{ post.engagement.view | number_format }}
                    <span
                      v-if="!summary.isFirst"
                      :class="{
                        'summary-increment-text': post.diffEngagement.view > 0
                      }"
                      >(+{{ post.diffEngagement.view | number_format }})</span
                    ></span
                  >
                </div>
              </div>
            </div>

            <div v-if="summary.topView.length">
              <br />
              <div>Top View:</div>
              <div v-for="(post, i) in summary.topView" :key="i">
                <div>{{ i + 1 }}. {{ post.link }}</div>
                <div class="ml-4">
                  <span
                    >Like: {{ post.engagement.like | number_format }}
                    <span
                      v-if="!summary.isFirst"
                      :class="{
                        'summary-increment-text': post.diffEngagement.like > 0
                      }"
                      >(+{{ post.diffEngagement.like | number_format }})</span
                    ></span
                  >&nbsp;<span
                    >{{ post.source === "twitter" ? "Retweet" : "Share" }}:
                    {{ post.engagement.retweet | number_format }}
                    <span
                      v-if="!summary.isFirst"
                      :class="{
                        'summary-increment-text':
                          post.diffEngagement.retweet > 0
                      }"
                      >(+{{
                        post.diffEngagement.retweet | number_format
                      }})</span
                    ></span
                  >&nbsp;<span
                    >Comment: {{ post.engagement.comment | number_format }}
                    <span
                      v-if="!summary.isFirst"
                      :class="{
                        'summary-increment-text':
                          post.diffEngagement.comment > 0
                      }"
                      >(+{{
                        post.diffEngagement.comment | number_format
                      }})</span
                    ></span
                  >&nbsp;<span
                    >View: {{ post.engagement.view | number_format }}
                    <span
                      v-if="!summary.isFirst"
                      :class="{
                        'summary-increment-text': post.diffEngagement.view > 0
                      }"
                      >(+{{ post.diffEngagement.view | number_format }})</span
                    ></span
                  >
                </div>
              </div>
            </div>

            <div
              v-if="
                summary.topMostActiveByRange.length &&
                summary.summaryLog.isShowTopMostActiveByRange
              "
            >
              <br />
              <div>
                Top Most Active (Create date
                <span v-if="summary.isFirst"
                  >วันที่ {{ summary.to_text }} น.</span
                ><span v-else
                  >วันที่ {{ summary.from_text }} น. ถึง
                  {{ summary.to_text }} น.</span
                >):
              </div>
              <div v-for="(post, i) in summary.topMostActiveByRange" :key="i">
                <div>{{ i + 1 }}. {{ post.link }}</div>
                <div class="ml-4">
                  <span
                    >Like: {{ post.engagement.like | number_format }}
                    <span
                      v-if="!summary.isFirst"
                      :class="{
                        'summary-increment-text': post.diffEngagement.like > 0
                      }"
                      >(+{{ post.diffEngagement.like | number_format }})</span
                    ></span
                  >&nbsp;<span
                    >{{ post.source === "twitter" ? "Retweet" : "Share" }}:
                    {{ post.engagement.retweet | number_format }}
                    <span
                      v-if="!summary.isFirst"
                      :class="{
                        'summary-increment-text':
                          post.diffEngagement.retweet > 0
                      }"
                      >(+{{
                        post.diffEngagement.retweet | number_format
                      }})</span
                    ></span
                  >&nbsp;<span
                    >Comment: {{ post.engagement.comment | number_format }}
                    <span
                      v-if="!summary.isFirst"
                      :class="{
                        'summary-increment-text':
                          post.diffEngagement.comment > 0
                      }"
                      >(+{{
                        post.diffEngagement.comment | number_format
                      }})</span
                    ></span
                  >&nbsp;<span
                    >View: {{ post.engagement.view | number_format }}
                    <span
                      v-if="!summary.isFirst"
                      :class="{
                        'summary-increment-text': post.diffEngagement.view > 0
                      }"
                      >(+{{ post.diffEngagement.view | number_format }})</span
                    ></span
                  >
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="summary.deletedLinks.length"
            class="alert bg-light-danger mt-5"
          >
            <br />
            <div class="text-danger">Link Deleted:</div>
            <div v-for="(post, i) in summary.deletedLinks" :key="i">
              <div>{{ i + 1 }}. {{ post.link }}</div>
              <div class="ml-4">
                <span>Like: {{ post.engagement.like | number_format }} </span
                >&nbsp;<span
                  >{{ post.source === "twitter" ? "Retweet" : "Share" }}:
                  {{ post.engagement.retweet | number_format }} </span
                >&nbsp;<span
                  >Comment: {{ post.engagement.comment | number_format }} </span
                >&nbsp;<span
                  >View: {{ post.engagement.view | number_format }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div v-else-if="!isReady">Generating...</div>
        <div v-else>No Data</div>
      </template>
    </KTCard>
    <ConfirmSentModal
      :summary="summary"
      :previous-summary="previousSummary"
      v-if="summary && summary.summaryLog.mode !== ''"
      @ok="$emit('ok')"
    ></ConfirmSentModal>
  </div>
</template>

<script>
import KTCard from "@/view/content/Card.vue"
import ConfirmSentModal from "@/view/content/custom/ConfirmSentModal.vue"
import { SummaryService } from "@/core/services"
export default {
  name: "SummaryData",
  components: { KTCard, ConfirmSentModal },
  props: {
    title: String,
    id: String,
    summary: {
      type: null,
      required: true
    },
    previousSummary: null,
    isReady: { type: Boolean, required: true }
  },
  data() {
    return {
      copyText: "Copy",
      copyClass: ""
    }
  },
  methods: {
    async updateStatus() {
      if (this.summary.summaryLog.mode === "preview") {
        let response = await SummaryService.update({
          mode: "save",
          summaryId: this.summary.summaryLog.summaryId
        })

        if (!response.error) {
          this.$root.$bvToast.toast("Update status success", {
            title: `Success`,
            variant: "success",
            solid: true
          })
          this.$emit("ok")
        } else {
          this.$root.$bvToast.toast("Update status fail", {
            title: `Error`,
            variant: "error",
            solid: true
          })
        }
      }
    },
    copy() {
      let element = this.id
      window.getSelection().removeAllRanges()
      let range = document.createRange()
      range.selectNode(
        typeof element === "string" ? document.getElementById(element) : element
      )
      window.getSelection().addRange(range)
      document.execCommand("copy")
      window.getSelection().removeAllRanges()
    },
    copyElementToClipboard() {
      this.copy()
      this.copyText = "Copied"
      this.copyClass = "example-copied"
      setTimeout(() => {
        this.copyClass = ""
        this.copyText = "Copy"
      }, 2000)
    }
  },
  computed: {
    sentimentData() {
      if (!this.summary) {
        return null
      }

      let sentiment = this.summary.summaryLog.sentiment

      let totalNeutral =
        Number(sentiment.neutral_dom) + Number(sentiment.neutral_manual)
      let totalNegKbank =
        Number(sentiment.negative_kbank_dom) +
        Number(sentiment.negative_kbank_manual)
      let totalNegBank =
        Number(sentiment.negative_bank_dom || 0) +
        Number(sentiment.negative_bank_manual || 0)
      let totalNegOther =
        Number(sentiment.negative_other_dom) +
        Number(sentiment.negative_other_manual)

      let totalPosKbank =
        Number(sentiment.positive_kbank_dom) +
        Number(sentiment.positive_kbank_manual)
      let totalPosBank =
        Number(sentiment.positive_bank_dom || 0) +
        Number(sentiment.positive_bank_manual || 0)
      let totalPosOther =
        Number(sentiment.positive_other_dom) +
        Number(sentiment.positive_other_manual)

      let totalCommentSummary =
        totalNeutral +
        totalNegKbank +
        totalNegBank +
        totalNegOther +
        totalPosKbank +
        totalPosBank +
        totalPosOther

      let percentNeutral =
        totalCommentSummary > 0 ? (totalNeutral / totalCommentSummary) * 100 : 0
      let percentNegKbank =
        totalCommentSummary > 0
          ? (totalNegKbank / totalCommentSummary) * 100
          : 0
      let percentNegBank =
        totalCommentSummary > 0
          ? (totalNegBank / totalCommentSummary) * 100
          : 0
      let percentNegOther =
        totalCommentSummary > 0
          ? (totalNegOther / totalCommentSummary) * 100
          : 0

      let percentPosKbank =
        totalCommentSummary > 0
          ? (totalPosKbank / totalCommentSummary) * 100
          : 0
      let percentPosBank =
        totalCommentSummary > 0
          ? (totalPosBank / totalCommentSummary) * 100
          : 0
      let percentPosOther =
        totalCommentSummary > 0
          ? (totalPosOther / totalCommentSummary) * 100
          : 0

      let totalComment = this.sourceCommentInfo.totalComment
      let totalPost = this.summary.summaryEngagement.totalPost.all
      let NAComment = totalPost + totalComment - totalCommentSummary

      return {
        totalNeutral,
        totalNegKbank,
        totalNegBank,
        totalNegOther,
        totalPosKbank,
        totalPosBank,
        totalPosOther,
        totalCommentSummary,
        percentNeutral,
        percentNegKbank,
        percentNegBank,
        percentNegOther,
        percentPosKbank,
        percentPosBank,
        percentPosOther,
        NAComment
      }
    },
    sourceCommentInfo() {
      if (!this.summary) {
        return { totalComment: 0, totalDiffComment: 0 }
      }

      let summary = this.summary.sourceEngagement
      let sources = Object.keys(summary)
      let totalComment = 0
      let totalDiffComment = 0
      for (let source of sources) {
        totalComment += summary[source].to.comment
        totalDiffComment += summary[source].diff.comment
      }
      return { totalComment, totalDiffComment }
    },
    sourceShareInfo() {
      if (!this.summary) {
        return { totalShare: 0, totalDiffShare: 0 }
      }

      let summary = this.summary.sourceEngagement
      let sources = Object.keys(summary)
      let totalShare = 0
      let totalDiffShare = 0
      for (let source of sources) {
        totalShare += summary[source].to.retweet
        totalDiffShare += summary[source].diff.retweet
      }
      return { totalShare, totalDiffShare }
    },
    sourceLikeInfo() {
      if (!this.summary) {
        return { totalLike: 0, totalDiffLike: 0 }
      }

      let summary = this.summary.sourceEngagement
      let sources = Object.keys(summary)
      let totalLike = 0
      let totalDiffLike = 0
      for (let source of sources) {
        totalLike += summary[source].to.like
        totalDiffLike += summary[source].diff.like
      }
      return { totalLike, totalDiffLike }
    },
    sourcePostCommentInfo() {
      if (!this.summary) {
        return { totalPostComment: 0, totalDiffPostComment: 0 }
      }

      let summary = this.summary.sourceEngagement
      let sources = Object.keys(summary)
      let totalPostComment = 0
      let totalDiffPostComment = 0
      for (let source of sources) {
        let comment = 0
        let diffComment = 0
        comment += summary[source].to.comment
        diffComment += summary[source].diff.comment

        totalPostComment += summary[source].to.totalPost + comment
        totalDiffPostComment += summary[source].diff.totalPost + diffComment
      }
      return { totalPostComment, totalDiffPostComment }
    }
  }
}
</script>
<style></style>
